import { Link } from 'gatsby';
import _ from 'lodash';
import React from 'react';
import { buildLinkPath } from '../../util/linkPaths';
import { Block, Inline } from '@contentful/rich-text-types';

export const EntryHyperlink = ({ data, children }: React.PropsWithChildren<Inline | Block>) => {
    const slug = _.get(data, 'target.fields.slug.en-US');
    const entryContentType = _.get(data, 'target.sys.contentType.sys.id');

    return <Link to={buildLinkPath(slug, entryContentType)}>{children}</Link>;
};
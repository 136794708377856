import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Img, { FluidObject } from 'gatsby-image'
import './hero.component.tsx';

type HeroConfiguration = {
    contentfulConfiguration: {
        heroSnippet: {
            childMarkdownRemark: {
                html: string;
                rawMarkdownBody: string
            }
        }
    };
    contentfulAsset: {
        fluid: FluidObject | FluidObject[]
    }
}

export const Hero = () => {
    const {contentfulConfiguration, contentfulAsset} = useStaticQuery<HeroConfiguration>(graphql`
    query HeroConfiguration {
        contentfulConfiguration(configuration: {eq: "Configuration"}) {
          heroSnippet {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
        }
        contentfulAsset(title: {eq: "Hero Image"}) {
            fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp
            }
        }
      }`)
    return (
        <React.Fragment>
            <section className="hero hero-bg-ready hero-fullscreen jarallax" data-speed="0.2" style={{ minHeight: 'calc(100vh - 140px)' }}>
                <div className="hero-background">
                    {/*<img src="assets/images/image-1.jpg" alt="hero background" className="jarallax-img" />*/}
                    <Img fluid={contentfulAsset.fluid} alt="hero background" className="jarallax-img" style={{position: 'static'}} />
                </div>
                <div className="hero-foreground hero-foreground-gradient-black-left d-flex flex-column justify-content-center">
                    <div className="container text-white py-14 py-md-23 py-lg-25">
                        <div
                            className="d-flex flex-column flex-lg-row text-center text-lg-left align-items-center align-items-lg-stretch justify-content-between">
                            <div className="mb-14 mb-lg-0 d-flex flex-column justify-content-between">
                                <div>
                                    {/*<small
                                    className="fs-2 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption">&nbsp;<br />&nbsp;</small>*/}
                                    <h1 className="fs-10 fs-lg-11 font-weight-bold" dangerouslySetInnerHTML={{__html: contentfulConfiguration.heroSnippet.childMarkdownRemark.rawMarkdownBody.replace('\n', '<br />')}}></h1>
                                </div>
                                <div className="d-none d-lg-block">
                                    <a href="#hero-end-anchor" className="btn btn-outline-light btn-square" onClick={(e) => { e.preventDefault(); document && document.getElementById('hero-end-anchor')?.scrollIntoView(); }}>
                                        <i className="material-icons">arrow_downward</i>
                                    </a>
                                </div>
                            </div>
                            <div className="card border-0 text-left" style={{ maxWidth: '390px', display: 'none' }}>
                                <div className="card-body text-body p-19 p-md-20">
                                    <h2 className="fs-6 fs-lg-9">Get a Free Consultation</h2>
                                    <form action="#" method="post">
                                        <div className="form-group"><input type="text" className="form-control" placeholder="First name" />
                                        </div>
                                        <div className="form-group"><input type="text" className="form-control" placeholder="Mobile phone" />
                                        </div>
                                        <div className="form-group form-check fs-2"><input type="checkbox" className="custom-checkbox"
                                            id="exampleCheck1" defaultChecked={true} /> <label className="form-check-label"
                                                htmlFor="exampleCheck1">I&apos;ve read and agree with the <a href="#">Terms &
                                            Policy</a></label></div>
                                        <div className="form-group mb-0"><button className="btn btn-primary w-100">Get a
                                        consultation</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="hero-end-anchor"></div>
        </React.Fragment>
    )
};

export default Hero;
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import style from './testimonials.component.module.scss';

type TestimonialsComponentQuery = {
    allContentfulTestimonial: {
        edges: {
            node: {
                attribution: string;
                quote: {
                    childMarkdownRemark: {
                        html: string;
                    }
                }
            }
        }[]
    }
}

const Testimonial = ({ quote, attribution }: { quote: string, attribution: string }) => (
    <article className={'col-sm-12 col-md-6 px-1'}>
        <div className="card card-ghost text-center h-100">
            <div className="card-body p-19 p-md-20">
                <p className="mb-11" dangerouslySetInnerHTML={{ __html: quote }}></p>
                <div className="mt-auto">
                    <h1 className="fs-5 lh-5 mb-2 mt-9">{attribution}</h1>
                </div>
            </div>
        </div>
    </article>
);

export const Testimonials = () => {
    const data = useStaticQuery<TestimonialsComponentQuery>(graphql`
    query TestimonialsComponentQuery {
        allContentfulTestimonial {
          edges {
            node {
              attribution
              quote {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }`);

    const testimonials = [
        ...data.allContentfulTestimonial.edges,
    ].sort(function () {
        return Math.random() - 0.5;
    });
    return (
        <section className="section text-white" data-speed="0.2" id="testimonials"><img
            className="section-background-image" src="assets/images/image-8.jpg" alt="background image" />
        <div className="section-background-color " style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
        <div className="section-content container text-center">
            <h1>Testimonials</h1>
        </div>
        <div className="section-content container mt-10 mt-md-19 pt-2">
            <div className={`${style.cardSlider} card-slider-testimonials align-items-stretch`}>
                {testimonials.slice(0, 2).map(({ node }, idx) => 
                    <Testimonial
                        key={idx}
                        attribution={node.attribution}
                        quote={node.quote.childMarkdownRemark.html} />)}
            </div>
        </div>
        <div className="section-content container mt-10 mt-md-19 pt-2 d-none d-sm-block">
            <div className="d-flex justify-content-center align-items-center text-center">
                <Link to="/testimonials" className="btn btn-lg btn-primary">Read What Others Have Said</Link>
            </div>
        </div>
        </section>
    );
};
import '@butztech/kammeyer-site-theme/build/assets/css/style.css';
import '@butztech/kammeyer-site-theme/build/assets/css/vendor.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import Helmet from 'react-helmet';
import { Footer } from './footer.component';
import './layout.component.scss';
import { Navigation } from './navigation.component';

export const Layout = ({ children }: React.PropsWithChildren<{ location: object }>) => (
    <React.Fragment>
        <Helmet></Helmet>
        <div id="page-start-anchor"></div>
        <Navigation />
        {children}
        <Footer />
    </React.Fragment>
);

export default Layout;
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import React from 'react';
import { buildLinkPath } from '../../util/linkPaths';

type NavigationQuery = {
    contentfulAsset: {
        fixed: FixedObject
    };
    contentfulNavigaton: {
        navigationLinks: {
            title: string;
            navigationTitle: string;
            slug: string;
            sys: {
                contentType: {
                  sys: {
                    id: string
                  }
                }
              }
        }[]
    }
}

export const Navigation = () => {
    const { contentfulAsset, contentfulNavigaton } = useStaticQuery<NavigationQuery>(graphql`
    query NavigationQuery {
        contentfulAsset(title: {eq: "Logo"}) {
          contentful_id
          fixed(height: 45, quality: 90, resizingBehavior: SCALE) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        contentfulNavigaton(title: {eq: "Navigation"}) {
            navigationLinks {
                title
                navigationTitle
                slug
                sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
            }
        }
      }`);

    return (
        <section className="header header-fixed-xl">
            <div className="header-main bg-white">
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg navbar-light w-100" id="header-navbar">
                            <Link to="/" className="navbar-brand font-weight-bold"><Img fixed={contentfulAsset.fixed} /></Link>
                            <button className="navbar-toggler"
                                type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><a className="nav-link text-uppercase" href="/#about">About</a></li>
                                    <li className="nav-item"><a className="nav-link text-uppercase" href="/#services">Services</a></li>
                                    {contentfulNavigaton.navigationLinks.filter(({slug}) => slug !== 'home').map(({title, navigationTitle, slug, sys}, idx) => (
                                        <li className="nav-item" key={`${slug}${idx}`}>
                                            <Link className="nav-link text-uppercase" to={buildLinkPath(slug, sys.contentType.sys.id)}>{navigationTitle || title}</Link>
                                        </li>
                                    ))}
                                    {/*<li className="nav-item"><a className="nav-link text-uppercase" data-toggle="smooth-scroll"
                                        href="#team">Team</a></li>
                                    <li className="nav-item"><a className="nav-link text-uppercase" data-toggle="smooth-scroll"
                                        href="#testimonials">Testimonials</a></li>
                                    <li className="nav-item"><a className="nav-link text-uppercase" data-toggle="smooth-scroll"
                                    href="#contacts">Contacts</a></li>*/}
                                </ul>
                                <div className="navbar-spacer"></div>
                                {/*<a className="btn btn-outline-primary text-nowrap" href="#contacts"
                                    data-toggle="smooth-scroll" target="_blank">Book now</a>*/}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Navigation;
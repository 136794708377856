import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { buildLinkPath } from '../../util/linkPaths';
import style from './services.component.module.scss';

const Service = ({ title, snippet, linkSlug, linkContentType }: { title: string, snippet: string, linkSlug: string, linkContentType: string }) => (
    <article className={`${style.cardSliderItem} col-sm-8 col-md-6 col-lg-5 col-xl-5 px-1`}>
        <div className="card border-0">
            <div className="card-body p-19 p-md-20">{/*<svg width="73" height="48" viewBox="0 0 73 48"
                xmlns="http://www.w3.org/2000/svg">
                <g fillRule="nonzero" fill="none">
                    <path
                        d="M.146 24.855c.178.356.474.623.845.741l9.274 3.185c.163.06.31.074.474.074.607 0 1.185-.385 1.392-.992l7.274-21.23.37.134a7.523 7.523 0 0 0 5.779-.326l.903-.445c1.082-.518 2.341-.607 3.467-.207l16.919 5.837c-.178.652-.519 1.407-1.112 1.822-.681.474-1.733.474-3.11 0l-7.586-2.593a1.467 1.467 0 0 0-1.17.104 1.5 1.5 0 0 0-.726.919c-.015.044-1.082 3.955-4.37 5.718-2.075 1.111-4.667 1.14-7.704.104-.77-.267-1.6.148-1.867.918-.267.77.148 1.6.919 1.867 1.777.607 3.466.919 5.037.919 1.822 0 3.496-.415 5.022-1.23 2.963-1.6 4.533-4.356 5.26-6.06l6.221 2.134c2.311.785 4.237.667 5.734-.37 2.355-1.63 2.518-4.815 2.533-5.17a1.5 1.5 0 0 0-.993-1.438L30.857 3.033a7.513 7.513 0 0 0-5.718.356l-.904.444a4.589 4.589 0 0 1-3.511.208l-1.496-.534a.983.983 0 0 0-.282-.133L9.716.159c-.77-.267-1.6.148-1.866.919L.072 23.744c-.118.356-.089.756.074 1.111zM10.161 3.404l6.489 2.222-6.815 19.896L3.346 23.3l6.815-19.896z"
                        fill="#ADB9BF" />
                    <path
                        d="M15.42 38.292c.726 1.482 2.178 2.341 3.719 2.4 0 .637.148 1.29.43 1.882a4.227 4.227 0 0 0 2.474 2.178c.459.163.933.237 1.392.237.652 0 1.289-.148 1.882-.445l.77-.385c.06.445.193.904.4 1.333a4.227 4.227 0 0 0 2.474 2.178c.46.163.933.237 1.393.237.651 0 1.289-.148 1.881-.444L49.08 39.21a43.37 43.37 0 0 0 10.31-7.037l3.764-3.452 8.607-4.207a1.459 1.459 0 0 0 .667-1.956L61.938 1.033a1.435 1.435 0 0 0-.844-.74 1.421 1.421 0 0 0-1.126.074l-8.8 4.31a1.459 1.459 0 0 0-.666 1.956l10.044 20.534-3.11 2.859a40.925 40.925 0 0 1-9.616 6.563L30.976 44.84a1.362 1.362 0 0 1-1.052.059 1.395 1.395 0 0 1-.785-.696 1.362 1.362 0 0 1-.06-1.052c.12-.341.37-.623.697-.785l9.422-4.608a1.459 1.459 0 0 0 .667-1.955 1.459 1.459 0 0 0-1.956-.667l-9.422 4.607-4.444 2.178a1.362 1.362 0 0 1-1.052.06 1.395 1.395 0 0 1-.786-.697 1.362 1.362 0 0 1-.059-1.052c.119-.34.37-.622.697-.785l13.51-6.607a1.459 1.459 0 0 0 .667-1.956 1.459 1.459 0 0 0-1.955-.667L19.88 37.641a1.392 1.392 0 0 1-1.837-.623 1.362 1.362 0 0 1-.06-1.051c.119-.341.37-.623.697-.786l5.985-2.933 7.778-3.807a1.459 1.459 0 0 0 .666-1.956 1.459 1.459 0 0 0-1.955-.667L19.05 31.744a1.392 1.392 0 0 1-1.837-.622 1.392 1.392 0 0 1 .622-1.837l6.43-3.14a1.459 1.459 0 0 0 .666-1.956 1.459 1.459 0 0 0-1.955-.667l-6.43 3.14a4.302 4.302 0 0 0-.755 7.26 4.235 4.235 0 0 0-.37 4.37zm38.356-31.63l6.163-3.021 9.244 18.888-6.163 3.023-9.244-18.89z"
                        fill="#3DB36B" />
                </g>
</svg>*/}
            <h1 className="mt-10 fs-6 fs-lg-9">{title}</h1>
            <p
                dangerouslySetInnerHTML={{
                    __html: snippet
                }}
            ></p><Link className="btn btn-link" to={buildLinkPath(linkSlug, linkContentType)}>Learn more</Link>
            </div>
        </div>
    </article>
);

type ServicesQuery = {
    allContentfulServices: {
        edges: {
            node: {
                title: string;
                childContentfulServicesSnippetTextNode: {
                    childMarkdownRemark: {
                        html: string;
                    }
                },
                link: {
                    slug: string,
                    sys: {
                        contentType: {
                            sys: {
                                id: string;
                            }
                        }
                    }
                }
            }
        }[]
    }
}

export const Services = () => {
    const data = useStaticQuery<ServicesQuery>(graphql`
    query ServicesQuery {
        allContentfulServices {
          edges {
            node {
              title
              childContentfulServicesSnippetTextNode {
                childMarkdownRemark {
                  html
                }
              }
              link {
                slug
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `);
    return (
        <section className="section bg-secondary" id="services">
            <div className="section-content container">
                <div className="d-flex justify-content-center justify-content-sm-between align-items-center">
                    <div className="text-center text-sm-left">
                        {/*<small
                            className="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">Areas
                        of practices</small>*/}
                        <h1>Services</h1>
                    </div>
                </div>
                <div className={`${style.cardSlider} card-slider-viewport card-slider-services mt-15 justify-content-center`}>
                    {
                        data.allContentfulServices.edges.map(({ node }) => (
                            <Service key={node.title} title={node.title} snippet={node.childContentfulServicesSnippetTextNode.childMarkdownRemark.html}
                                linkSlug={node.link.slug} linkContentType={node.link.sys.contentType.sys.id} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
};


export default Services;
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { buildLinkPath } from '../../util/linkPaths';

type WelcomeCallToActionConfigurationQuery = {
    contentfulConfiguration: {
        welcomeSectionCallToActionText: string
        welcomeSectionCallToActionLink: {
            slug: string
            sys: {
                contentType: {
                    sys: {
                        id: string
                    }
                }
            }
        }
    }
};

export const About = ({ children }: React.PropsWithChildren<unknown>) => {
    const { contentfulConfiguration } = useStaticQuery<WelcomeCallToActionConfigurationQuery>(graphql`
    query WelcomeCallToActionConfigurationQuery {
        contentfulConfiguration(configuration: {eq: "Configuration"}) {
          welcomeSectionCallToActionText
          welcomeSectionCallToActionLink {
            slug
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }`);

    return (
        <section className="section" id="about">
            <div className="section-content container">
                <div className="row text-center text-lg-left">
                    <div className="col-12 col-lg-6 mb-12 mb-lg-0 d-flex align-items-center">
                        <div className="pr-0 pr-lg-10">
                            <h1 className="mb-0">Welcome</h1>
                            <div className="fs-5 text-muted"></div>
                            <div className="mt-8 mb-11">
                                {children}
                            </div>
                            {
                                contentfulConfiguration.welcomeSectionCallToActionText && contentfulConfiguration.welcomeSectionCallToActionLink?.slug ?
                                <Link className="btn btn-lg btn-primary" to={buildLinkPath(contentfulConfiguration.welcomeSectionCallToActionLink.slug, contentfulConfiguration.welcomeSectionCallToActionLink.sys.contentType.sys.id)}>{contentfulConfiguration.welcomeSectionCallToActionText}</Link> :
                                null
                            }
                        </div>
                    </div>
                    <div className="col-12 col-lg-6"><img src="assets/images/image-2.jpg" className="img-fluid w-100"
                        alt="welcome image" /></div>
                </div>
            </div>
        </section>
    )
};

export default About;
/* eslint react/display-name: 0 */
import React, { ReactNode } from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';  
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { EmbeddedEntry } from './embedded-entry.component';
import { EmbeddedAsset } from './embedded-asset.component';
import { EntryHyperlink } from './entry-hyperlink.component';
import { AssetHyperlink } from './asset-hyperlink.component';
import { ErrorBoundary } from '../error-boundary';
import { Block, Document, Inline } from '@contentful/rich-text-types';


// const Bold = ({ children }) => <span className="bold">{children}</span>;
// const Text = ({ children }) => <p className="align-center">{children}</p>;

const options: Options = {
    //renderMark: {
    //    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    //},
    renderNode: {
        //[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline, children: ReactNode) => {
            console.log('node >>>', node, children);
            return <EmbeddedEntry {...node} />;
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
            return <EmbeddedAsset {...node} />;
        },
        [INLINES.ENTRY_HYPERLINK]: (node: Inline | Block, children: ReactNode) => {
            console.log(node, children);
            return <EntryHyperlink {...node}>{children}</EntryHyperlink>;
        },
        [INLINES.ASSET_HYPERLINK]: (node: Inline | Block, children: ReactNode) => {
            console.log(node, children);
            return <AssetHyperlink {...node}>{children}</AssetHyperlink>;
        }
    },
};

export const GatsbyRichText = ({ body }: {body: Document}) => {
    return (
        <ErrorBoundary>
            {documentToReactComponents(body, options)}
        </ErrorBoundary>
    );
};

export default GatsbyRichText;
import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import _ from 'lodash';
import React from 'react';
import remark from 'remark';
import remarkHTML from 'remark-html';
import { slugify } from '../../../util/slug';
import style from './person.component.module.scss';

type PersonEntryImages = {
    allContentfulAsset: {
      edges: {
        node: {
          contentful_id: string,
          fluid: FluidObject
        }
      }[]
    }
  }

export const Person = (fields: Record<string, unknown>) => {
    const name = _.get(fields, 'name.en-US') as string;
    const title = _.get(fields, 'title.en-US') as string;
    const email = _.get(fields, 'email.en-US') as string;
    const phone = _.get(fields, 'phone.en-US') as string;
    const shortBio = _.get(fields, 'shortBio.en-US') as string;
    const imageId = _.get(fields, 'image.en-US.sys.contentful_id', '') as string;
    const imageTitle = _.get(fields, 'image.en-US.fields.title.en-US', '') as string;

    const data = useStaticQuery<PersonEntryImages>(graphql`
    query PersonEntryImages {
        allContentfulAsset {
          edges {
            node {
              contentful_id
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
    }`);
    const imageMap = data.allContentfulAsset.edges.reduce((acc, cur) => (
        {
            ...acc,
            [cur.node.contentful_id]: cur.node.fluid
        }
    ), {}) as Record<string, FluidObject>;
    const imageData = imageMap[imageId] || null;
    return (
        <section className={style.person}>
        <div className="row">
            <div className="col-12 order-0">
              <h2 id={slugify(name)}>{name}</h2>
              <p>{title}<br /><a href={`tel:${phone}`}>{phone}</a><br /><a href={`mailto:${email}`}>{email}</a></p>
            </div>
            <div className="col-12 col-md-8 order-2 order-md-1">
                
                <div dangerouslySetInnerHTML={{
                    __html: remark()
                        .use(remarkHTML as any) // eslint-disable-line
                        .processSync(shortBio).toString()
                }}></div>
            </div>
            <div className="col-12  col-md-4 order-1 order-md-2">
                {imageData && <Img alt={imageTitle} title={imageTitle} fluid={imageData} style={{maxWidth: 400}} />}
            </div>
        </div>
      </section>
    );
};
import React from 'react';

export const Content = ({ title, subtitle, children }: React.PropsWithChildren<{ title: string; subtitle?: string }>) => (
    <section className="section">
        <div className="section-content container">
            <div className="row">
                <div className="col-12">
                    <div className="pr-0 pr-lg-10">
                        <h1 className="mb-0">{title}</h1>
                        {subtitle && <div className="fs-5 text-muted">{subtitle}</div>}
                        <div className="mt-8 mb-11">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Content;
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import Img from 'gatsby-image';

type FooterQuery = {
    allContentfulSocialMediaAccount: {
        nodes: {
            title: string
            url: string
            visible: boolean
        }[]
    }
    contentfulAsset: {
        fixed: FixedObject
      }
}

export const Footer = () => {
    const {allContentfulSocialMediaAccount, contentfulAsset} = useStaticQuery<FooterQuery>(graphql`
    query FooterQuery {
        allContentfulSocialMediaAccount(sort: {fields: title}, filter: {visible: {eq: true}}) {
          nodes {
            title
            url
            visible
          }
        }
        contentfulAsset(title: {eq: "Logo"}) {
            fixed(height: 45, quality: 90, resizingBehavior: SCALE) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
      }`);

    return (
        <section className="footer bg-secondary">
            <div className="container py-19 py-sm-23 py-lg-25">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 col-lg-5 mb-14 mb-md-0">
                        <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                            <Link to="/" className="footer-brand"><Img fixed={contentfulAsset.fixed} /></Link>
                            {allContentfulSocialMediaAccount.nodes.length > 0 && <div className="brand-icons-list ml-10 ml-sm-20">
                                {allContentfulSocialMediaAccount.nodes.map(({ title, url }) => (
                                    <a key={title} title={title} href={url} className={`brand-icon brand-icon-circle brand-icon-${title.toLowerCase()}`}>
                                        <i className={`fa fa-${title.toLowerCase()}`}></i>
                                    </a>
                                ))}
                            </div>}
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 text-center d-none d-lg-block">
                        <a href="#page-start-anchor" className="footer-icon" onClick={(e) => { e.preventDefault(); document && document.getElementById('page-start-anchor')?.scrollIntoView(); }}>
                            <i className="material-icons">arrow_upward</i>
                        </a>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 text-center text-md-right">
                        <ul className="footer-menu">
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms &amp; Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Footer;
import React from 'react';
import _ from 'lodash';
import Img, { FluidObject } from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Block, Inline } from '@contentful/rich-text-types';

type EmbeddedAssetImages = {
  allContentfulAsset: {
    edges: {
      node: {
        contentful_id: string,
        fluid: FluidObject
      }
    }[]
  }
}

export const EmbeddedAsset = ({ data}: Block | Inline) => {
    const imageId = _.get(data, 'target.sys.contentful_id');
    const imageTitle = _.get(data, 'target.fields.title.en-US');

    const graphQlData = useStaticQuery<EmbeddedAssetImages>(graphql`
    query EmbeddedAssetImages {
        allContentfulAsset {
          edges {
            node {
              contentful_id
              fluid(quality: 95) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
    }`);
    const imageMap = graphQlData.allContentfulAsset.edges.reduce((acc, cur) => (
        {
            ...acc,
            [cur.node.contentful_id]: cur.node.fluid
        }
    ), {}) as Record<string, FluidObject>;

    const imageData = imageMap[imageId] || null;

    return (
        <Img alt={imageTitle} title={imageTitle} fluid={imageData} />
    );
};
import React, { ReactNode } from 'react';

export class ErrorBoundary extends React.Component<{children: ReactNode}, {hasError: boolean; error: null | Error; errorInfo: unknown}> {
    constructor(props: {children: ReactNode}) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: unknown) {
        this.setState({error, errorInfo});
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <React.Fragment>
                    <h1>Something went wrong.</h1>
                    <pre>{JSON.stringify({error: this.state.error, errorInfo: this.state.errorInfo})}</pre>
                </React.Fragment>);
        }

        return this.props.children;
    }
}
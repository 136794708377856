import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import _ from 'lodash';
import React from 'react';
import remark from 'remark';
import remarkHTML from 'remark-html';
import { slugify } from '../../../util/slug';
import style from './person.component.module.scss';

export const Testimonial = (fields: Record<'attribution' | 'quote', Record<'en-US', string>>) => (
    <blockquote className="blockquote">
        <p className="mb-0">{fields.quote["en-US"]}</p>
        <footer className="blockquote-footer">{fields.attribution["en-US"]}</footer>
    </blockquote>
);
import React from 'react';
import _ from 'lodash';
import { Person } from './entries/person.component';
import { Block, Inline } from '@contentful/rich-text-types';
import { Testimonial } from './entries/testimonial.component';

export const EmbeddedEntry = ({ data }: Block | Inline) => {
    const contentType = _.get(data, 'target.sys.contentType.sys.id');
    const fields = _.get(data, 'target.fields');

    if (contentType === 'person') {
        return <Person {...fields} />;
    } else if(contentType === 'testimonial') {
        return <Testimonial {...fields} />
    }
    throw new Error(`Unknown Embedded Entry. Content Type "${contentType}"`);
};
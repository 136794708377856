import { graphql, Link, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';

type OurTeamQuery = {
    allContentfulPerson: {
        nodes: {
            name: string;
            email: string;
            phone: string;
            title: string;
            shortBio: {
                childMarkdownRemark: {
                    excerpt: string;
                }
            }
            image: {
                fluid: FluidObject | FluidObject[]
            }
        }[]
    }
}

const TeamMemberOption = ({ name, title, image, idx, isSelected, onClick }: {
    name: string, title: string, image: {
        fluid: FluidObject | FluidObject[]
    }, idx: number, isSelected: boolean, onClick: () => void
}) => (
    <a onClick={onClick} onMouseOver={onClick} className={`tour-tabs-nav-link ${isSelected ? 'active' : ''}`}>
        <div>
            <Img fluid={image.fluid} style={{ width: 50, height: 50 }} title={name} alt={name} className="rounded-circle" />
        </div>
        <div className="ml-9">
            <h2 className="fs-5 mb-1">{name}</h2>
            <p className="mb-0 text-muted fs-3">{title}</p>
        </div>
    </a>
);
const TeamMemberDisplay = ({ name, title, image, phone, email, shortBio, isSelected }: {
    name: string, title: string, image: {
        fluid: FluidObject | FluidObject[]
    }, phone: string; email: string, shortBio: string, isSelected: boolean
}) => (
    <div className={`tab-pane fade ${isSelected ? 'show active': ''}`}>
        <div className="row">
            <div className="col-12 col-sm-5">
                <Img fluid={image.fluid} style={{ maxWidth: 250 }} title={name} alt={name} className="w-100 h-100 object-fit-cover" />
            </div>
            <div className="col-12 col-sm-7 mt-14 mt-sm-0">
                <h2 className="fs-6 fs-lg-9">{name}</h2>
                <div className="fs-5 text-muted">{title}</div>
                <p className="mt-8" dangerouslySetInnerHTML={{ __html: shortBio }}></p>
                <Link to="/our-team" className="btn btn-sm btn-primary">Read More</Link>
                {/*<div className="brand-icons-list mt-10">
                        <a href="#"
                            className="brand-icon brand-icon-circle brand-icon-facebook"><i
                                className="fa fa-facebook-f"></i> </a>
                        <a href="#"
                            className="brand-icon brand-icon-circle brand-icon-instagram"><i
                                className="fa fa-instagram"></i> </a>
                        <a href="#"
                            className="brand-icon brand-icon-circle brand-icon-vimeo"><i
                                className="fa fa-vimeo-v"></i></a>
                    </div>*/}
            </div>
        </div>
    </div>
);

export const Team = () => {
    const [selectedIdx, setSelectedIdx] = useState(0);
    const data = useStaticQuery<OurTeamQuery>(graphql`
    query OurTeamQuery {
        allContentfulPerson(sort: {fields: displayOrder}) {
            nodes {
                name
                email
                phone
                title
                shortBio {
                    childMarkdownRemark {
                        excerpt(format: HTML, pruneLength: 200)
                    }
                }
                image {
                    fluid(maxWidth: 260, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
          }
        }
      }`);
    return (
        <section className="section" id="team">
            <div className="section-content container">
                {/*<small
                    className="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">Professionals</small>*/}
                <h1>Meet the Team</h1>
                <div className="row mt-17 tour-tabs">
                    <div className="col-12 col-lg-4 pr-lg-0">
                        <div className="tour-tabs-nav tour-tabs-nav-left nav">
                            {data.allContentfulPerson.nodes.map((person, idx) => (
                                <TeamMemberOption
                                    onClick={() => setSelectedIdx(idx)}
                                    key={idx} idx={idx} {...person}
                                    isSelected={idx === selectedIdx}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 pl-lg-0 mt-14 mt-lg-0 d-flex">
                        <div className="tour-tabs-content tour-tabs-content-right tab-content">
                            {data.allContentfulPerson.nodes.map((person, idx) => (
                                <TeamMemberDisplay
                                    {...person}
                                    shortBio={person.shortBio.childMarkdownRemark.excerpt}
                                    isSelected={idx === selectedIdx}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;